export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE DE TABLA",
    dataIndex: "nombre_tabla",
    key: "nombre_tabla",
    width: 320
  },
  {
    title: "NRO REGISTROS",
    dataIndex: "totalregistros_f",
    key: "totalregistros_f",
    width: 110
  },
  {
    title: "ESTADO",
    dataIndex: "estado",
    key: "estado",
    slots: { customRender: "estado" },
    width: 100
  },
  {
    title: "FECHA DE CARGA",
    dataIndex: "fechaingreso_f",
    key: "fechaingreso_f",
    width: 100
  },
  {
    title: "FECHA DE CORTE",
    dataIndex: "fechacorte_f",
    key: "fechacorte_f",
    width: 100
  },
  {
    title: "ACCIONES",
    key: "acciones",
    slots: { customRender: "acciones" },
    width: 100
  }
];

export const breadcrumb = [
  {
    path: "menu",
    breadcrumbName: "Import./Export."
  },
  {
    path: "/cargar-base-datos",
    breadcrumbName: "Cargar base de datos"
  }
];
