<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'EDITAR REGISTRO' : 'EDITAR REGISTRO']"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="$emit('closeModal')"
    class="w-full md:max-w-2xl"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" layout="vertical" ref="formRef" class="flex gap-3">
      <a-form-item label="Fecha Corte" name="fechacorte_f">
        <a-date-picker
            class="w-full"
            v-model:value="formState.fechacorte_f"
            @change="onDatechange"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYY"
            :showToday="false"
          />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed } from "vue";
import { notification } from "ant-design-vue";
import CargaBaseDatosApi from "@/api/cargaBaseDatos";

export default {
  props: {
    carga: {
      type: Object,
      default: () => ({

      })
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const carga = props.carga; // se define porque tiene que ser unico en editar

    // const isEdit = !!props.parametro.idparametro;

    const formState = reactive(carga);

    onMounted(() => {

    });

    const onDatechange = (dateMoment) => {
      formState.fechacorte_f = dateMoment.format("YYYY-MM-DD");
    };


    function handleOk() {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";

          try {
            
            response = await CargaBaseDatosApi.editOne(payload.idimportacion, payload.fechacorte_f);
            emit("closeModal");
          } catch (error) {
            console.log(error);
            notification.error({
              message: "Operación inválida",
              description: "Se produjo un error, inténtelo más tarde"
              // description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    }

    function handleCancel() {
      emit("fetchData");
      emit("closeModal");
    }

    return {
      handleOk,
      handleCancel,
      formState,
      formRef,
      onDatechange
    };
  }
};
</script>
