import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_UPDATE_ID = "/api/Service/V1/Public";

export default {
  getAll: (currentPage, username, tipo) => {
    // console.log(new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' }));

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_importacion",
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@USERNAME",
          value: `${username}`, // PONER USERNAME DEL USUARIO
          type: "string"
        },
        {
          name: "@TIPO",
          value: tipo,
          type: "int"
        }
      ]
    };

    // console.log(request);
    return post(API_SERVICE_QUERY, request);
  },

  getEnlacePlantilla: (parametro) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_parametro_by_denominacion",
          type: "string"
        },
        {
          name: "@PARAMETRO",
          value: parametro,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  editOne: (idimportacion, fechacorte_f) => {
    const request = {
      _type: 3,
      _table: "sivi_migr.importacion",
      _id: "idimportacion",
      _petitions: [
        {
          name: "fechaactualizar",
          value: fechacorte_f,
          type: "string"
        }
      ]
    };
    return put(API_UPDATE_ID + "/" + idimportacion, request);
  }
};
