<template>
  <!-- <a-page-header
    @back="goBack"
    title="IMPORTAR INFORMACIÓN"
    :breadcrumb="{ routes }"
    class="px-0"
  /> -->

  <a-page-header @back="() => $router.go(-1)" title="CARGAR BASE DE DATOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Import./Export.</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Cargar base de datos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap justify-between mb-8">
    <a-alert class="md:w-10/12">
      <template #description>
        <div class="mb-2">
          En esta ventana se cargan todas las bases de datos del SIVINUR, se pueden agregar las
          tramas las cuales si no están creadas se crean.
        </div>

        <p>✅ Formatos válidos .csv y .zip</p>
        <p>✅ Se actualiza al último subido</p>
        <p>✅ El nombre del archivo se guardará como tabla en la base de datos</p>
        <p>
          ✅ Ejemplo de archivos: <a :href="url_sien_antropometria">sien_antropometria_new.csv</a>,
          <a :href="url_sien_anemia">sien_anemia_new.csv</a>,
          <a :href="url_maestro_paciente">maestro_paciente.csv</a>,
          <a :href="url_maestro_personal">maestro_personal.csv</a>,
          <a :href="url_nacimiento_cnv">nacimiento_cnv_new.csv</a>,
          <a :href="url_padron_nominal">padron_nominal_new.csv</a>,
          <a :href="url_padron_nominal_obs">padron_nominal_obs.csv</a>
        </p>

        <p class="italic text-gray-400 my-2">
          Tener en cuenta que si dentro del archivo contiene fechas erróneas o no coinciden con el
          nombre del archivo (si termina en _2021_1.csv y dentro del archivo las fechas son
          2021/07/01 - de julio cuando debería ser de enero) mostrará un error.
        </p>
      </template>
    </a-alert>
    <button @click="descargarPlantilla" type="button" class="text-center px-3 md:w-2/12">
      <div class="text-3xl text-green-600">
        <DownloadOutlined />
      </div>
      <p>
        Descargar <br />
        plantilla
      </p>
    </button>
  </div>

  <div class="lg:flex lg:gap-4">
    <a-upload-dragger
      v-model:fileList="fileList"
      accept=".csv,.zip"
      name="file"
      :multiple="true"
      :action="urlAction"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="onChangeUpload"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">Haz click o arrastra en este área para subir</p>
      <p class="ant-upload-hint p-8">
        Se aceptan csv (delimitado por comas) y zip. En zip se puede enviar más de 1 archivo.
      </p>
    </a-upload-dragger>

    <div class="mt-8 lg:m-0">
      <div class="overflow-x-auto w-full">
        <a-table
          :columns="columns"
          :data-source="data"
          size="small"
          :pagination="false"
          rowKey="idimportacion"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        >
          <template #rangepages="{ index }">
            <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
          </template>

          <template #estado="{ text: estado }">
            <!-- <span>
              <a-tag v-if="estado === '0'" color="red"> Error </a-tag>
              <p v-if="estado === '1'" class="flex text-xs">
                <a-spin size="small" />&nbsp;cargando
              </p>
              <a-tag v-if="estado === '2'" color="green"> Exitoso </a-tag>
            </span> -->

            <a-tag v-if="estado === '2'" color="success">
              <template #icon>
                <CheckCircleOutlined />
              </template>
              exitoso
            </a-tag>

            <a-tag v-if="estado === '1'" color="processing">
              <template #icon>
                <SyncOutlined :spin="true" />
              </template>
              procesando
            </a-tag>

            <a-tag v-if="estado === '0'" color="error">
              <template #icon>
                <CloseCircleOutlined />
              </template>
              error
            </a-tag>
          </template>
          <template #acciones="{ record }">
            <div class="flex gap-2 items-center">
              <a-tooltip title="Editar registro">
                <a-button size="small" @click="showModalEdit(record)">
                  <template #icon><EditOutlined /></template>
                </a-button>
              </a-tooltip>
            </div>
          </template>
        </a-table>
      </div>
      <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
        <div class="flex">
          <a-pagination
            v-model:current="currentPage"
            v-model:pageSize="pageSize"
            :total="totalDocs"
          />
        </div>

        <p class="text-gray-300 font-semibold">Archivos cargados las últimas 24 horas</p>
      </footer>

      <CargaAddEdit
        v-if="isVisible"
        :carga="carga"
        @closeModal="closeModal"
        @fetchData="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, toRaw, onMounted } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { message, notification } from "ant-design-vue";
import { columns, breadcrumb } from "./utilsImportar";
import { numberWithCommas } from "@/utils";
import UsuariosApi from "@/api/user";
import CargaBaseDatosApi from "@/api/cargaBaseDatos";
import { getUsuario } from "@/utils/auth";
import moment from "moment";
import CargaAddEdit from "./CargaAddEdit.vue";
import {
  EditOutlined,
  InboxOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    // icons
    InboxOutlined,
    EditOutlined,
    DownloadOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CargaAddEdit
  },
  data: function () {
    return {
      intervalid1: ""
    };
  },
  methods: {
    todo: function () {
      this.intervalid1 = setInterval(() => {
        this.fetchData();
      }, 3000);
    }
  },
  mounted: function () {
    UsuariosApi.userLogAdd("Import./Export.", "Carga Base de Datos");
    this.todo();
  },
  unmounted: function () {
    clearInterval(this.intervalid1);
  },
  setup() {
    const carga = ref(null);
    const pageSize = ref(10);
    const store = useStore();
    const isLoading = ref(false);
    const data = ref([]);
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const urlPlantilla = ref("");
    const urlAction = process.env.VUE_APP_UPLOAD_API;
    const { establecimiento } = store.state.user.usuario;
    const isVisible = ref(false);

    const url = process.env.VUE_APP_BASE_API + "/";
    const url_sien_antropometria =
      url + "PLANTILLAS/sin_anemia_antrometria/sien_antropometria_new.csv";
    const url_sien_anemia = url + "PLANTILLAS/sin_anemia_antrometria/sien_anemia_new.csv";
    const url_maestro_paciente = url + "PLANTILLAS/Maestro_Paciente.csv";
    const url_maestro_personal = url + "PLANTILLAS/Maestro_Personal.csv";
    const url_nacimiento_cnv = url + "PLANTILLAS/nacimiento_cnv_new.xlsx";
    const url_padron_nominal = url + "PLANTILLAS/padron_nominal_new.xlsx";
    const url_padron_nominal_obs = url + "PLANTILLAS/padron_nominal_obs.xlsx";

    const obtieneEnlacePlantilla = () => {
      CargaBaseDatosApi.getEnlacePlantilla("PLANTILLA_CARGADB")
        .then((res) => {
          urlPlantilla.value = res.data[0].valor;
        })
        .catch((err) => console.log(err));
    };

    function showModalEdit(record) {
      carga.value = record;
      isVisible.value = true;
    }

    const beforeUpload = (file) => {
      const isZipOrCSV =
        file.type === "application/zip" ||
        file.type === "text/csv" ||
        file.type === "application/x-zip-compressed" ||
        file.type === "application/vnd.ms-excel";

      if (!isZipOrCSV) {
        notification.error({
          message: "Operación inválida",
          description: "Solo se aceptan archivos de tipo .zip y .csv"
        });
      }
      return isZipOrCSV;
    };

    const onChangeUpload = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.file.response?.errores?.length) {
          notification.error({
            message: "Operación inválida",
            description: info.file.response?.errores[0].mensaje
          });
        }
      }

      if (status === "done") {
        message.success(`${info.file.name} Archivo agregado correctamente.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Falló el subir el archivo.`);
      }
    };

    const fetchData = () => {
      isLoading.value = true;
      CargaBaseDatosApi.getAll(toRaw(currentPage.value), getUsuario(), "1")
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    watch(currentPage, () => {
      fetchData();
    });

    onMounted(() => {
      UsuariosApi.userLogAdd("Impotar-exportar", "Carga Base de Datos", establecimiento);
      fetchData();
      obtieneEnlacePlantilla();
    });

    const datosFormated = computed(() =>
      data.value.map((d) => {
        return {
          ...d,
          totalregistros_f: numberWithCommas(d.totalregistros),
          fechaingreso_f: d.fechaingreso,
          fechacorte_f: d.fechaactualizar
        };
      })
    );

    function closeModal() {
      isVisible.value = false;
    }

    const descargarPlantilla = () => {
      window.open(url + "PLANTILLAS-20230227T043724Z-001.zip", "_blank");
    };

    return {
      goBack: () => router.back(),
      fileList: ref([]),
      routes: breadcrumb,
      onChangeUpload,
      headers: {
        authorization: "Bearer " + store.getters.token
      },
      data: datosFormated,
      isLoading,
      fetchData,
      currentPage,
      totalDocs,
      pageSize,
      columns,
      urlAction,
      descargarPlantilla,
      beforeUpload,
      showModalEdit,
      showModalEdit,
      isVisible,
      closeModal,
      carga,
      url,
      url_sien_antropometria,
      url_sien_anemia,
      url_maestro_paciente,
      url_maestro_personal,
      url_nacimiento_cnv,
      url_padron_nominal,
      url_padron_nominal_obs
    };
  }
};
</script>

<style></style>
